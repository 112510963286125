import React from "react";
import SmallCardComponent from "./common/SmallCardComponent";
import TextListComponent from "./common/TextListComponent";
import styles from "./SolucionesPage.module.css";
import {} from "react-router-dom";

const LandingImg = "/assets/technologies/landing.png";

const cardData = [
  {
    title: "Inteligencia de negocios",
    description:
      "Generamos reportes y trabajamos con la información para dar valor agregado a la toma de desiciones de su empresa",
    icon: "/assets/icons/business.png",
  },
  {
    title: "Servicio de distribución de notificaciones",
    description:
      "Si su empresa necesita llegar a más clientes, un sistema de notificaciones le permitira ampliar su impacto y cobertura",

    icon: "/assets/icons/notification.png",
  },
  {
    title: "Geolocalización y mapeo en mapas",
    description:
      "Contamos con soluciones basadas en mapas y geo-cordenadas, tanto para plataformas web como móviles.",

    icon: "/assets/icons/maps.png",
  },
  {
    title: "Sistemas de backup y restauración",
    description:
      "Principalmente enfocado en mantener su información segura y con la capacidad de ser recuperada ante casos inesperados",

    icon: "/assets/icons/backup.png",
  },
  {
    title: "Soluciones con respuesta en tiempo real",
    description:
      "En la actualidad cada vez se requieren de servicios que provean respuesta y actualizaciones en tiempo real.",
    icon: "/assets/icons/signal.png",
  },
];

const frontData = [
  {
    id: "frontdata1",
    title: "ReactJS:",
    description:
      "ReactJS: Realizamos proyectos basados en la librería de Javascript más utilizada del desarrollo web. Esta biblioteca fue desarrollada por Facebook en el 2013 y hasta la actualidad es mantenida no solamente por dicha compañía, sino que por una gran comunidad de desarrolladores independientes y compañías, lo cual la convierte en una opción flexible para el desarrollo de aplicaciones web.",
  },
  {
    id: "frontdata2",
    title: "Angular:",
    description:
      "Angular: Contamos con proyectos desarrollados con Angular, el cual es uno de los frameworks de trabajo basados en Javascript más populares utilizado para el desarrollo “Front-end”. En la actualidad Google es la empresa que se encarga de su mantenimiento y actualizaciones. Un aspecto que podemos resaltar de esta tecnología es que es una “suite” completa para desarrollar aplicaciones web robustas y  escalables.",
  },
  {
    id: "frontdata3",
    title: "Vue: ",
    description:
      "Vue: Nuestra pasión por el desarrollo también incluye a Vue, el cual es el tercer framework más popular para el desarrollo web. Podemos pensar en Vue como una “capa” añadida a Javascript  que se enfoca en crear aplicaciones web completas.",
  },
];

const backData = [
  {
    id: "node",
    title: "NodeJs",
    description:
      "NodeJS: Es uno de nuestros frameworks de desarrollo para programación en servidores, este es un entorno que nos permite ejecutar código Javascript del lado del servidor y nos permite levantar sitios web dinámicos y eficientes. En la actualidad podemos utilizar dicho lenguaje para desarrollar aplicaciones de IOT (Internet de las cosas), aplicaciones de transmisión de datos, basadas en REST API y aplicaciones basadas en mensajería instantánea.",
  },
  {
    id: "nest",
    title: "NestJs",
    description:
      "NestJS: Contamos con proyectos que hacen uso de dicho framework, similar a express, este está basado en Node pero proporciona mejoras en la estructuración de los proyectos, como el uso de Typescript y la estructuración de sistemas modulares, lo que lo convierten en una alternativa sólida para desarrollar, dar mantenimiento y actualizaciones a aplicaciones ya existentes.",
  },
];

const mobileData = [
  {
    id: "flutter",
    title: "Flutter",
    description:
      "Flutter: Cuando se trata de aplicaciones móviles multiplataforma, flutter es nuestra opción preferida, este framework utiliza el lenguaje de Dart y nos permite desarrollar aplicaciones tanto para sistemas basados en Android y IOS, una de las principales ventajas de Flutter es que compila en código nativo y permite realizar el diseño de interfaces de una manera rápida y sencillas. Asimismo fue desarrollado por Google y es la principal compañía que le brinda soporte y actualizaciones.",
  },
  {
    id: "reactn",
    title: "React Native",
    description:
      "React Native: Creado por Facebook, es una de nuestras alternativas para el desarrollo de aplicaciones nativas tanto para iOS y Android, similar a flutter, este es un framework de desarrollo híbrido que utiliza una única base de código compartida entre ambas plataformas. A diferencia de Flutter, este es mantenido por Facebook y está basado en React y Javascript",
  },
  {
    id: "kotlin",
    title: "Kotlin",
    description:
      "Kotlin: Cuando se trata de programación nativa en Android nuestra principal opción es Kotlin, el cual es un lenguaje de programación que fue creado por la compañía “JetBrains” y que en la actualidad se ha ganado tal popularidad que Google realiza sus actualizaciones primero en esta plataforma y luego en JAVA, el cual era el lenguaje por defecto anteriormente para el desarrollo nativo en Android.",
  },
  {
    id: "ios",
    title: "Swift",
    description:
      "Swift: Cuando se trata de aplicaciones nativas para los sistemas IOS, esta es nuestra opción por defecto, creada por Apple, este lenguaje de programación se utiliza para crear aplicaciones en todo el ecosistema de IOS, desde los celulares hasta sus aplicaciones de escritorio.",
  },
];

const otrosData = [
  {
    id: "docker",
    title: "Docker",
    description:
      "Docker: Es una herramienta útil al momento de crear, probar e implementar aplicaciones rápidamente, ya que mediante la creación de contenedores, en Docker, podemos empaquetar todas las dependencias del aplicativo desarrollado y desplegarlo en diferentes sistemas operativos.",
  },
  {
    id: "git",
    title: "Git",
    description:
      "Git: Es nuestro principal gestor de versiones y un estándar en la industria de desarrollo de software, nos brinda flexibilidad para trabajar y colaborar con bases de código conjuntas, podemos incluir a GitHub y GitLab como nuestras principales opciones.",
  },
  {
    id: "kuber",
    title: "Kubernetes",
    description:
      "Kubernetes: Es una plataforma portable en la cual podemos administrar la carga de trabajo y servicios, Kubernetes está basada en la gestión de contenedores de Docker para crear aplicaciones escalables y orquestar cargas de trabajo en proyectos desplegados.",
  },
  {
    id: "fire",
    title: "Firebase",
    description:
      "Firebase: Es una plataforma creada por Google la cual nos permite desarrollar aplicaciones de calidad de manera rápida, entre algunos de los servicios que podemos acceder de Firebase son: Sistema de notificaciones, mensajería de texto, base de datos, autenticación telefonica, cloud functions, entre otros.",
  },
  {
    id: "aws",
    title: "AWS",
    description:
      "AWS: Amazon Web services es un proveedor de servicios en la nube, si lo que se quiere es desplegar servicios basados en la nube, esta es la opción. Nos permite desplegar proyectos sin necesidad de invertir de manera inicial en temas de infraestructura o servidores.",
  },
];

function SolucionesPage() {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <img
            src={LandingImg}
            className={styles.header_image}
            alt="iconimage"
          ></img>
          <h3 className={styles.headerText}>Nuestras soluciones</h3>
        </div>
        <div className={styles.content_container}>
          {/*
        <div className={styles.solution_container}>
          <div className={styles.card}></div>
          <div className={styles.card}></div>
          <div className={styles.card}></div>
        </div>
        */}
          <br></br>
          {/* 5 Cards section */}
          <div>
            <div className={styles.header_text}>Brindamos soluciones para:</div>
            <div className={styles.flex_container}>
              <SmallCardComponent data={cardData[0]} />
              <SmallCardComponent data={cardData[1]} />
              <SmallCardComponent data={cardData[2]} />
              <SmallCardComponent data={cardData[3]} />
              <SmallCardComponent data={cardData[4]} />
            </div>
          </div>
          <br></br>
          {/* First section */}
          <div className={styles.container}>
            <div className={styles.header_text}>Nuestras tecnologias web</div>
            <div className={styles.section_container}>
              <img
                src="/assets/technologies/web.png"
                className={styles.image}
                alt=""
              ></img>
              <TextListComponent data={frontData} />
            </div>
          </div>
          <br></br>
          {/* Second section */}
          <div className={styles.container}>
            <div className={styles.header_text}>Tecnologias en backend</div>
            <div className={styles.section_container2}>
              <TextListComponent data={backData} />
              <div className={styles.card_image}>Imagen</div>
            </div>
          </div>
          <br></br>
          {/* Third section */}
          <div className={styles.container}>
            <div className={styles.header_text}>Para aplicativos móviles</div>
            <div className={styles.section_container}>
              <div className={styles.card_image}>Imagen</div>
              <TextListComponent data={mobileData} />
            </div>
          </div>

          {/* Fourth section */}
          <div className={styles.container}>
            <div className={styles.header_text}>Otras tecnologias</div>
            <div className={styles.section_container2}>
              <TextListComponent data={otrosData} />
              <div className={styles.card_image}>Imagen</div>
            </div>
          </div>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default SolucionesPage;
