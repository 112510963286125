import { useLayoutEffect, useState } from "react";

export default function useWindowPosition(id, trigger) {
  const [animation, setAnimation] = useState(false);

  useLayoutEffect(() => {
    function updatePosition() {
      const offsetHeight = window.document.getElementById(id).offsetHeight;
      console.log("windowpageOffset", window.pageYOffset, offsetHeight * 1.17);
      if (window.pageYOffset > offsetHeight * trigger) {
        setAnimation(true);
      }
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, [id, trigger]);
  return animation;
}
