import { Box } from "@mui/system";
import React from "react";
import FrameworkCard from "./common/FrameworkCard";
import WhiteCard from "./common/WhiteCard";
import styles from "./ServicesPage.module.css";
import { useTranslation } from "react-i18next";


const stacks = [
  {
    img: "./assets/services/logos/angular.png",
    title: "Angular",
    content:
      "Es un Framework de JavaScript de código abierto escrito en TypeScript. Su objetivo principal es desarrollar aplicaciones de una sola página. Google se encarga del mantenimiento y constantes actualizaciones de mejoras para este framework. Angulas es un framework basado en componentes para crear aplicaciones web escalables. Una colección de bibliotecas bien integradas que cubren una amplia variedad de características, que incluyen enrutamiento, administración de formularios, comunicación cliente-servidor y más.",
  },
  {
    img: "./assets/services/logos/react.png",
    title: "React",
    content:
      "React es una biblioteca de Javascript para construir interfaces de usuario de forma sencilla. Fue lanzada en el año 2013 y desarrollada por Facebook, quienes también la mantienen actualmente junto a una comunidad de desarrolladores independientes y compañías. Desde su lanzamiento, su uso ha ido incrementando notablemente, convirtiéndose en una de las tecnologías front-end más utilizadas. Esta sirve para desarrollo front end en aplicaciones web. ",
  },
  {
    img: "./assets/services/logos/vue.png",
    title: "Vue",
    content:
      "Es un framework progresivo Javascript para crear interfaces de usuario, o en otras palabras, se trata de una “capa” añadida a Javascript formada por herramientas, convenciones de trabajo y un lenguaje particular que permite crear páginas web completas de forma rápida, agradable, sencilla y muy práctica.",
  },
  {
    img: "./assets/services/logos/kotlin.png",
    title: "Kotlin",
    content:
      "Es un lenguaje de programación de código abierto creado por JetBrains que se ha popularizado gracias a que se puede utilizar para programar aplicaciones Android. Desde el año 2019 Google ha declarado que todos los nuevos desarrollos se realizarán primero en  Kotlin y luego en Java por lo cual se ha convertido en la principal opción para el desarrollo de aplicaciones nativas en Android.",
  },
  {
    img: "./assets/services/logos/flutter.png",
    title: "Flutter",
    content:
      "Es un framework que usa Dart como único código y nos permite desarrollar aplicaciones tanto para sistemas basados en Android y IOS, una de las principales ventajas de Flutter es que compila en código nativo y permite realizar el diseño de interfaces de una manera rápida y sencillas. Asimismo fue desarrollado por Google y es la principal compañía que le brinda soporte y actualizaciones.",
  },
  {
    img: "./assets/services/logos/swift.png",
    title: "Swift",
    content:
      "Creado por Apple, este lenguaje de programación se utiliza para crear aplicaciones en todo el ecosistema de IOS, desde los celulares hasta sus aplicaciones de escritorio.",
  },
  {
    img: "./assets/services/logos/reactnative.png",
    title: "React Native",
    content:
      " Creado por Facebook, es una de nuestras alternativas para el desarrollo de aplicaciones nativas tanto para iOS y Android, similar a flutter, este es un framework de desarrollo híbrido que utiliza una única base de código compartida entre ambas plataformas. A diferencia de Flutter, este es mantenido por Facebook y está basado en React y Javascript.",
  },
  {
    img: "./assets/services/logos/express.png",
    title: "Express",
    content:
      "Basado en NodeJS es uno de los frameworks de desarrollo para programación en servidores más utilizados en la actualidad, este es un entorno que nos permite ejecutar código Javascript del lado del servidor y nos permite levantar sitios web dinámicos y eficientes. En la actualidad podemos utilizar dicho lenguaje para desarrollar aplicaciones de IOT (Internet de las cosas), aplicaciones de transmisión de datos, basadas en REST API y aplicaciones basadas en mensajería instantánea.",
  },
  {
    img: "./assets/services/logos/nodejs.png",
    title: "NodeJS",
    content:
      "Node.js es un entorno para ejecutar código de JavaScript. Este entorno de tiempo es open source, es decir, de código abierto, multiplataforma y que se ejecuta del lado del servidor. Este sirve para crear sitios web dinámicos muy eficientes, escritos con el lenguaje de programación JavaScript. Por ejemplo, en aplicaciones IOT (Internet de las cosas), aplicaciones de transmisión de datos, aplicaciones basadas en REST API o aplicaciones de mensajería instantánea lo más utilizado es Node.js, ya que resulta muy estable y rápido frente a miles de conexiones simultáneas.",
  },
  {
    img: "./assets/services/logos/nestjs.png",
    title: "Nest",
    content:
      "Similar a express, este está basado en Node pero proporciona mejoras en la estructuración de los proyectos, como el uso de Typescript y la estructuración de sistemas modulares, lo que lo convierten en una alternativa sólida para desarrollar, dar mantenimiento y actualizaciones a aplicaciones ya existentes. ",
  },
  {
    img: "./assets/services/logos/postgre.png",
    title: "PostgreSQL",
    content:
      "PostgreSQL es un sistema de código abierto de administración de bases de datos del tipo relacional, aunque también es posible ejecutar consultas que sean no relaciones. En este sistema, las consultas relacionales se basan en SQL, mientras que las no relacionales hacen uso de JSON. Dos detalles a destacar de PostgreSQL es que posee data types (tipos de datos) avanzados y permite ejecutar optimizaciones de rendimiento avanzadas, que son características que por lo general solo se ven en sistemas de bases de datos comerciales, como por ejemplo SQL Server de Microsoft u Oracle de la compañía homónima.",
  },
  {
    img: "./assets/services/logos/mysql.png",
    title: "MYSQL",
    content:
      "MySQL es el sistema de gestión de bases de datos relacional más extendido en la actualidad al estar basada en código abierto. Este cuenta con una doble licencia. Por una parte es de código abierto, pero por otra, cuenta con una versión comercial gestionada por la compañía Oracle. Una de las principales ventajas de MYSQL es que trabaja con bases de datos relacionales, es decir, utiliza tablas múltiples que se interconectan entre sí para almacenar la información y organizarla correctamente.",
  },
  {
    img: "./assets/services/logos/mongo.png",
    title: "Mongo DB",
    content:
      "Es un sistema de gestión de base de datos no relacional. Las bases de datos NoSQL no utilizan ni tablas ni registros como harían las bases de datos relacionales como SQL; no necesitan una estructura fija. Esto da más flexibilidad a la hora de diseñar el  esquema de datos y sus relaciones. Estas bases de datos son muy utilizadas en la industria en la actualidad y surgieron por la necesidad de que una base de datos pueda trabajar con una gran cantidad de información de forma eficiente.",
  },
  {
    img: "./assets/services/logos/firebase.png",
    title: "Firebase",
    content:
      " Se trata de una plataforma móvil creada por Google, cuya principal función es desarrollar y facilitar la creación de apps de elevada calidad de una forma rápida, con el fin de que se pueda aumentar la base de usuarios y ganar más dinero. Firebase brinda diversos servicios dentro de su suite, como mensajería de texto, base de datos, medios de autenticación telefónica, funciones en la nube, entre otros. ",
  },
  {
    img: "./assets/services/logos/docker.png",
    title: "Docker",
    content:
      "Es una herramienta útil al momento de crear, probar e implementar aplicaciones rápidamente, ya que mediante la creación de contenedores, en Docker, podemos empaquetar todas las dependencias del aplicativo desarrollado y desplegarlo en diferentes sistemas operativos.",
  },
  {
    img: "./assets/services/logos/git.png",
    title: "Git",
    content:
      "Git es la mejor opción para la mayoría de los equipos de software actuales. dado que provee funcionalidad, rendimiento, seguridad y flexibilidad para trabajar, compartir y colaborar con bases de código de cada aplicativo. Mediante esta herramienta un equipo de desarrollo de software puede integrar diferentes bases de código a un mismo proyecto y gestionar las diferentes versiones de un sistema.",
  },
  {
    img: "./assets/services/logos/kubernetes.png",
    title: "Kubernetes",
    content:
      "Es una plataforma portable y extensible de código abierto para administrar cargas de trabajo y servicios. Este facilita la automatización y la configuración declarativa de un flujo de trabajo. Esta plataforma ofrece un entorno de administración centrado en contenedores el cual orquesta la infraestructura de cómputo, redes y almacenamiento para que las cargas de trabajo de los usuarios no tengan que hacerlo. Nos permite hacer nuestras aplicaciones escalables y sin tener preocupaciones de las versiones y librerías específicas para cada proyecto.",
  },
  {
    img: "./assets/services/logos/aws.png",
    title: "Amazon web services",
    content:
      "Amazon Web Services es un proveedor de servicios en la nube, nos permite disponer de almacenamiento, recursos de computación, aplicaciones móviles, bases de datos y un largo etcétera en modalidad de cloud computing. En el caso de que se necesiten recursos computacionales, se puede contratar los servicios de AWS para conseguir dicha capacidad sin hacer una inversión en infraestructura, ya que todo se encuentra alojado en la nube y está disponible desde internet.",
  },
];

function ServicesPage() {

  const { t, ready } = useTranslation();
  if (!ready) return 'loading translations...';

  const services = t('services', { returnObjects: true });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <h2>  {t("servicesTitle")}</h2>
          <p>
            {t("servicesDescription")}
          </p>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <br></br>
        <h3 className={styles.headerText}> {t("servicesSubTitle")}</h3>
        <div className={styles.flex_container}>
          {services.map((service) => (<WhiteCard data={service} />))}
        </div>
        <br></br>
        <h3 className={styles.headerText}>{t("servicesTools")}</h3>
        <br></br>
        <div className={styles.flex_techcontainer}>
          {stacks.map((stack) => (
            <FrameworkCard data={stack} key={stack.img} />
          ))}

          {/*     <FrameworkCard /> */}
        </div>
      </div>
      <Box height="20px"></Box>
    </>
  );
}

export default ServicesPage;
