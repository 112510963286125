import { Grid } from "@mui/material";
import React from "react";
import styles from "./MainSection.module.css";
import { useTranslation } from "react-i18next";

const LandingImg = "/assets/landing.png";

const lngs = {
	en: { nativeName: "English" },
	es: { nativeName: "Español" },
};

function MainSection() {
	const { t, i18n } = useTranslation();

	return (
		<div className={styles.header_section}>
			<div className={styles.text_container_alt}>
				<h1 className={styles.textTittle}>ITC</h1>
				<h3 className={styles.textDescription}>{t("main.descripcion")}</h3>
				<a href="/contacts" className={styles.ecButton}>
					{t("main.button")}
				</a>
			</div>
			{/*
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <div className={styles.text_container}>
            <h1 className={styles.textTittle}>ITC</h1>
            <h3 className={styles.textDescription}>{t("main.descripcion")}</h3>
            <a href="/contacts" className={styles.ecButton}>
              {t("main.button")}
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <div className={styles.img_container}>
            <img src={LandingImg} className={styles.landing_img} alt=""></img>
          </div>
        </Grid>
      </Grid>
      */}
		</div>
	);
}

export default MainSection;

/*
       <div className={styles.header_block}>
          <h1>Soluciones a tus problemas</h1>
          <h1>
            Description of the webpage with a description of atleast 3 lines of
            text
          </h1>
          <h1>Button</h1>
        </div>
*/
