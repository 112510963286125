import { Grid, Container } from "@mui/material";
import styles from "./CardComponent.module.css";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

function CardComponent(props) {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <Grid className={styles.card} container spacing={0}>
        {props.isReverse ? (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              order={{ md: 2, lg: 2, xs: 1, xl: 2 }}
            >
              <Container className={styles.cardContent}>
                <div className={styles.inlineSection}>
                  <img
                    src={props.data.icon}
                    className={styles.icon_image}
                    alt="iconimage"
                  ></img>
                  {/*  <h3 className={styles.title}>{props.data.title}</h3> */}
                  <h3 className={styles.title}>{props.title}</h3>
                </div>
                <h6 className={styles.cardBody}>{props.description}</h6>
                <Link to={props.data.navigation}>
                  <h6 className={styles.cardLink}>{props.link}</h6>
                </Link>
              </Container>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              order={{ md: 1, lg: 1, xs: 2, xl: 1 }}
            >
              <img
                src={props.data.image}
                className={styles.cardImage}
                alt=""
              ></img>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              order={{ md: 1, lg: 1, xs: 1, xl: 1 }}
            >
              <Container className={styles.cardContent}>
                <div className={styles.inlineSection}>
                  <img
                    src={props.data.icon}
                    className={styles.icon_image}
                    alt="iconimage"
                  ></img>
                  <h3 className={styles.title}>{props.title}</h3>
                </div>
                <h6 className={styles.cardBody}>{props.description}</h6>

                <Link to={props.data.navigation}>
                  <h6 className={styles.cardLink}>{props.link}</h6>
                </Link>
              </Container>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              order={{ md: 2, lg: 2, xs: 2, xl: 2 }}
            >
              <img
                src={props.data.image}
                className={styles.cardImage}
                alt=""
              ></img>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default CardComponent;
