import { Box, Grid, Grow } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import useWindowPosition from "../../hook/useWindowPosition";
import CardComponent from "./../common/CardComponent";
import { useTranslation } from "react-i18next";
import styles from "./IndustrySection.module.css";

const data = {
  sectionOne: {
    image: "/assets/industry.png",
    icon: "/assets/icon_industry.png",
    navigation: "/industry",
  },
  sectionTwo: {
    image: "/assets/mining.png",
    icon: "/assets/icon_housing.png",
    navigation: "/state",
  },
  sectionThree: {
    image: "/assets/public.png",
    icon: "/assets/icon_mining.png",
    navigation: "/public",
  },
};

function IndustrySection() {
  const [animationOne, setAnimationOne] = useState(false);
  const [animationTwo, setAnimationTwo] = useState(false);
  const [animationThree, setAnimationThree] = useState(false);

  const checkedAnimationOne = useWindowPosition("header", 0.49);
  const checkedAnimationTwo = useWindowPosition("header", 0.65);
  const checkedAnimationThree = useWindowPosition("header", 1.17);

  const { t } = useTranslation();

  useEffect(() => {
    setAnimationOne(true);
    setAnimationTwo(true);
    setAnimationThree(true);
  }, []);

  return (
    <Box
      id="industry"
      sx={{
        width: "100%",
        //backgroundImage: `url(${"images/wallpaper/main.png"})`,
        position: "relative", //"absolute"
        //opacity: [0.8, 0.9, 0.9],
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#EBF1F4",

        pt: {
          sm: "20px",
          xs: "10px",
        },
        pr: {
          lg: "0px",
          md: "0px",
          sm: "15px",
          xs: "15px",
        },
        pl: {
          lg: "0px",
          md: "0px",
          sm: "15px",
          xs: "15px",
        },
      }}
    >
      <h1 className={styles.heading}>{t("main.sectorText")}</h1>
      <Grid container spacing={4}>
        <Grow
          in={checkedAnimationOne}
          {...(animationOne ? { timeout: 1500 } : {})}
        >
          <Grid item xs={12} sm={12} md={6}>
            <CardComponent
              data={data.sectionOne}
              title={t("main.industry")}
              description={t("main.industryDescription")}
              link={t("main.industriLink")}
              isReverse={true}
            />
          </Grid>
        </Grow>

        <Grow
          in={checkedAnimationTwo}
          {...(animationTwo ? { timeout: 1500 } : {})}
        >
          <Grid item xs={12} sm={12} md={6}>
            <CardComponent
              data={data.sectionTwo}
              title={t("main.inmobilaria")}
              description={t("main.inmobilariaDescription")}
              link={t("main.inmobilariaLink")}
              isReverse={false}
            />
          </Grid>
        </Grow>

        <Grow
          in={checkedAnimationThree}
          {...(animationThree ? { timeout: 1500 } : {})}
        >
          <Grid item xs={12} sm={12} md={12}>
            <CardComponent
              data={data.sectionThree}
              title={t("main.goverment")}
              description={t("main.govermentDescription")}
              link={t("main.govermentLink")}
              isReverse={true}
            />
          </Grid>
        </Grow>
      </Grid>
    </Box>
  );
}

export default IndustrySection;
