import React from "react";
import styles from "./About.module.css";
import { useTranslation } from "react-i18next";


function About() {

  const { t } = useTranslation();
  return (
    <>
      <img src="./assets/about_us.png" alt="img" className={styles.img} />
      <div className={styles.section_wrapper}>
        <h2>  {t("about.aboutUS")}</h2>
        <p>
          {t("about.aboutUSdescription")}
        </p>
        <br></br>
        <h2>{t("about.values")}</h2>
        <p>
          {t("about.valuesDescription")}
        </p>

        <div className={styles.grid_container}>
          <div className={styles.flex_container}>
            <img
              src="/assets/commitment.png"
              className={styles.icon_image}
              alt="iconimage"
            ></img>
            <h3>{t("about.value1")}</h3>
          </div>
          <div className={styles.flex_container}>
            <img
              src="/assets/profesionalism.png"
              className={styles.icon_image}
              alt="iconimage"
            ></img>
            <h3>{t("about.value2")}</h3>
          </div>
          <div className={styles.flex_container}>
            <img
              src="/assets/creativity.png"
              className={styles.icon_image}
              alt="iconimage"
            ></img>
            <h3>{t("about.value3")}</h3>
          </div>
        </div>
        <div className={styles.spacing}></div>
      </div>
    </>
  );
}

export default About;
