import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F6791B", //Pallete orange
    },
    secondary: {
      main: "#1E5584", //Pallete blue
    },
    error: {
      main: "#F73141", //Pallete red
    },
    success: {
      main: "#007A7A", //Pallete dark green
    },
    info: {
      main: "#031D43", //Blue Logo
    },
    warning: {
      main: "#FDA307", //Palette orange
    },
    text: {
      primary: "#2E384D", //Black
      secondary: "#8C9CB1", //Grey
      error: "#F73141", //Pallete red
      warning: "#FDA307", //Palette orange
      info: "#031D43", //Blue Logo
      success: "#007A7A", //Dark green
    },
  },
  typography: {
    fontFamily: "Josefin Sans",
    fontWeightLight: 300,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    h6: {
      fontSize: "2em",
    },
  },
});

export default theme;
