import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import styles from "./Header.module.css";
import { ImInstagram, ImFacebook } from "react-icons/im";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";

function HideOnScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window, //? window() : undefined
	});
	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

function Header(props) {
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [selected, setSelected] = useState("PE");

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	function handleCloseNavMenu(event) {
		setAnchorElNav(null);
	}
	const { t, i18n } = useTranslation();

	const pages = [
		{ title: t("navbar.option1"), route: "/" },
		{ title: t("navbar.option2"), route: "/#systems" },
		{ title: t("navbar.option3"), route: "/#industry" },
		{ title: t("navbar.option4"), route: "/about" },
		{ title: t("navbar.option5"), route: "/contacts" },
	];

	return (
		<AppBar
			position="static"
			sx={{ backgroundColor: "#EBF1F4", boxShadow: "none" }}>
			<div className={styles.wrapper}>
				<Toolbar disableGutters>
					<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="primary">
							<MenuIcon />
						</IconButton>

						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}>
							{pages.map((page) => (
								<MenuItem
									component="a"
									href={page.route}
									key={page.title}
									onClick={handleCloseNavMenu}>
									<Typography textAlign="center">{page.title}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>

					<Container
						maxWidth="xs"
						component="a"
						href="/"
						sx={{
							backgroundImage: `url(${"assets/navbar_logo.png"})`,
							display: { xs: "flex", md: "flex" },
							height: {
								md: 60,
								xs: 45,
							},
							width: {
								md: 105,
								xs: 115,
							},
							backgroundSize: "cover",
							m: 0,
							mr: "5px",
							position: "relative",
							backgroundRepeat: "no-repeat",
						}}></Container>

					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", md: "flex" },
						}}>
						{/*        component={Link}
                  to={page.route}*/}
						{pages.map((page) => (
							<Button
								key={page.title}
								component="a"
								href={page.route}
								onClick={handleCloseNavMenu}
								className={styles.text_button}
								sx={{
									my: 2,
									color: "text.primary",
									textTransform: "none",
									fontSize: "1.1em",
								}}>
								{page.title}
							</Button>
						))}
					</Box>

					<Box sx={{ flexGrow: 0 }}>
						{/*  <IconButton sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton> */}

						<div className={styles.headerContainer}>
							<Avatar
								sx={{
									display: "inline-flex",
									bgcolor: "transparent",
									"&:hover": {
										bgcolor: "#DBBAC9",
									},
								}}
								className={styles.iconContainer}
								component="a"
								href="/344">
								<ImInstagram className={styles.icon} fontSize="1em" />
							</Avatar>
							<Avatar
								sx={{
									display: "inline-flex",
									bgcolor: "transparent",
									"&:hover": {
										bgcolor: "#DBBAC9",
									},
								}}
								className={styles.iconContainer}
								component="a"
								href="/404">
								<ImFacebook className={styles.icon} fontSize="1em" />
							</Avatar>

							<ReactFlagsSelect
								className={styles.countryFlag}
								selected={selected}
								onSelect={(code) => {
									setSelected(code);
									if (code === "US") {
										setSelected(code);
										i18n.changeLanguage("en");
									} else {
										i18n.changeLanguage("es");
										setSelected(code);
									}
								}}
								countries={["US", "PE"]}
							/>
						</div>
					</Box>
				</Toolbar>
			</div>
		</AppBar>
	);
}
export default Header;

/*

 <HideOnScroll {...props}>
    </HideOnScroll>



    <Avatar
                sx={{
                  display: "inline-flex",
                  bgcolor: "#F5DFCF",
                  "&:hover": {
                    bgcolor: "#DBBAC9",
                  },
                }}
                className={styles.iconContainer}
                component="a"
                href="/344"
              >
                <ImInstagram className={styles.icon} fontSize="1em" />
              </Avatar>
              <Avatar
                sx={{
                  display: "inline-flex",
                  bgcolor: "#F5DFCF",
                  "&:hover": {
                    bgcolor: "#DBBAC9",
                  },
                }}
                className={styles.iconContainer}
                component="a"
                href="/404"
              >
                <ImFacebook className={styles.icon} fontSize="1em" />
              </Avatar>
 */
