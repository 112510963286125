import React from "react";
import ContactUsButton from "../common/ContactUsButton";
import styles from "./BackendDevelopment.module.css";

export default function BackendDevelopment() {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.textWrapper}>
					<h2>DESARROLLO DE SOLUCIONES BACKEND</h2>
					<p>
						Desarrollamos soluciones del lado del servidor o BACK-END para
						solventar todas sus necesidades, exponemos información al cliente y
						mediante inteligencia de negocios generamos reportes de diversa
						índole para apoyarlo en la toma de desiciones.
					</p>
				</div>
			</div>

			<div className={styles.content}>
				<div>
					<br></br>
					<h4 className={styles.contentColor}>
						DESARROLLAMOS SOLUCIONES DE SERVIDOR
					</h4>
					<h3> Desarrollo de soluciones Back-end</h3>
					<p>
						En ITC desarrollamos soluciones integrales y estas incluyen el uso
						de servidores para el almacenamiento y procesamiento de la
						información. Somos concientes de que en la actualidad un aplicativo
						no únicamente almacena información sino que necesita mostrar data de
						forma oportuna y sintetizada. Nosotros utilizamos algoritmos y
						sistemas de generación de reportes para brindar data de valor a
						nuestros clientes.
					</p>
				</div>
				<img
					src="/assets/services/backImage.png"
					className={styles.img}
					alt="keeperImage"></img>
			</div>
			<div className={styles.contacus}>
				<h1>¡Ponte en contacto y solicita una cotización!</h1>
				<ContactUsButton />
			</div>
		</>
	);
}
