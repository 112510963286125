import React, { useState } from "react";
import styles from "./Contacts.module.css";
import Grid from "@mui/material/Grid";
import { Formik } from "formik";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { Box, Typography, Button, TextField } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Message from "./common/SnackBarMessage";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

function Contacts() {
	const [nameError, setNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [dataError, setDataError] = useState(false);
	const [notify, setNotify] = useState({ isOpen: false });

	const { t } = useTranslation();

	return (
		<>
			<img src="./assets/contacts.png" alt="img" className={styles.img} />
			<div className={styles.wrapper}>
				<Grid container spacing={1} className={styles.content}>
					<Grid item xs={12} sm={12} md={6}>
						<h2 className={styles.textHeader}> {t("contact.title")} </h2>
						<p className={styles.textContent}>{t("contact.description")}</p>
						<h3 className={styles.textSubtitle}>{t("contact.invitation")} </h3>

						<Box
							sx={{
								m: 0,
								p: 0,
								display: "flex",
								flexDirection: "row",
							}}>
							<EmailIcon sx={{ mr: 1 }} />
							<h3 className={styles.textContent}>{t("contact.email")}</h3>
						</Box>
						<Box height={7}></Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
							}}>
							<CallIcon sx={{ mr: 1 }} />
							<h3 className={styles.textContent}>{t("contact.cellphone")}</h3>
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<h2 className={styles.textHeader}> {t("contact.formTitle")}</h2>

						<Box height={10}></Box>

						{/* Formulario Formik */}
						<Formik
							initialValues={{ email: "", name: "", data: "" }}
							validate={(values) => {
								const errors = {};
								setNameError(false);
								setEmailError(false);
								setDataError(false);

								if (!values.email) {
									errors.email = "*Campo obligatorio";
									setEmailError(true);
								} else if (
									!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
								) {
									errors.email = "*Dirección invalida";
									setEmailError(true);
								}

								if (!values.name) {
									errors.name = "*Campo obligatorio";
									setNameError(true);
								}

								if (!values.data) {
									errors.data = "*Campo obligatorio";
									setDataError(true);
								}

								return errors;
							}}
							onSubmit={(values, { setSubmitting, resetForm }) => {
								setTimeout(() => {
									const toSend = {
										email: values.email,
										name: values.name,
										data: values.data,
									};

									//send
									emailjs
										.send(
											"service_mcou07i",
											"template_axuto0x",
											toSend,
											"OvMAzPYU--nxd7RQj"
										)
										.then((response) => {
											console.log("SUCCESS!", response.status, response.text);
											setNotify({
												isOpen: true,
												message:
													"Hemos recepcionado su email, en breve nos pondremos en contacto!",
												type: "success",
											});
										})
										.catch((err) => {
											console.log("FAILED...", err);
											setNotify({
												isOpen: true,
												message:
													"Ha ocurrido un error, por favor inténtelo más tarde.",
												type: "error",
											});
										});

									//alert(JSON.stringify(values, null, 2));
									setSubmitting(false);
									resetForm();
								}, 400);
							}}>
							{({
								values,
								errors,
								handleChange,
								handleSubmit,
								isSubmitting,
							}) => (
								<form
									//noValidate
									//autoComplete='off'
									onSubmit={handleSubmit}>
									<TextField
										id="outlined-multiline-static"
										label={t("contact.formPlaceholder1")}
										multiline
										rows={4}
										fullWidth
										name="data"
										onChange={handleChange}
										value={values.data}
										error={dataError}
										helperText={errors.data}
									/>
									<Box height={20}></Box>
									<TextField
										name="email"
										error={emailError}
										helperText={errors.email}
										onChange={handleChange}
										value={values.email}
										type="email"
										fullWidth
										label={t("contact.formPlaceholder2")}
									/>
									<Box height={20}></Box>
									<TextField
										name="name"
										error={nameError}
										helperText={errors.name}
										onChange={handleChange}
										value={values.name}
										fullWidth
										label={t("contact.formPlaceholder3")}
										id="fullWidth"
									/>
									<Box height={20}></Box>
									<Button
										variant="contained"
										type="submit"
										disabled={isSubmitting}
										endIcon={
											<ArrowForwardIosIcon style={{ color: "whitesmoke" }} />
										}
										sx={{
											textTransform: "none",
											borderRadius: "30px",
											backgroundColor: "#f67a1b",
											"&:hover": {
												backgroundColor: "#d33e84",
											},
										}}>
										<Typography
											variant="body1"
											color="inherit"
											sx={{ color: "whitesmoke" }}>
											{t("contact.sendButton")}
										</Typography>
									</Button>
								</form>
							)}
						</Formik>
						{/* End section */}

						<Message notify={notify} setNotify={setNotify} />
					</Grid>
				</Grid>
			</div>
		</>
	);
}

export default Contacts;
