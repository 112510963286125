import React from "react";
import styles from "./WebSystems.module.css";
import ContactUsButton from "../common/ContactUsButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function WebSystems() {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.textWrapper}>
					<h2>DESARROLLO DE DESARROLLO WEB</h2>
					<p>
						Desarrollamos aplicaciones web de corte empresarial, nos enfocamos
						en la experiencia de usuario final y sus requerimientos de
						funcionalidad.
					</p>
				</div>
			</div>

			<div className={styles.content}>
				<div>
					<br></br>
					<h4 className={styles.contentColor}>
						DESARROLLAMOS SOLUCIONES WEB ENFOCADAS EN EL USUARIO
					</h4>
					<h3> Desarrollo de aplicaciones Web</h3>
					<p>
						En ITC adoptamos las últimas tecnologias para el desarrollo web y
						mediante nuestro enfoque en el cliente, brindamos soluciones
						sencillas que sean amigables al usuario final y permitan solventar
						sus requerimientos. Asimismo, nosotros promovemos activamente la
						portabilidad de una aplicación, por lo que cada solución es
						realizada aplicando la metodologia de "Mobile first" que significa
						que nuestras aplicaciones serán funcionales pensando en la movilidad
						del usuario, que se puedan acceder desde cualquier lugar y desde
						cualquier dispositivo como son los celulares, tablets, etc.
					</p>
				</div>
				<img
					src="/assets/services/webImage.png"
					className={styles.img}
					alt="keeperImage"></img>
			</div>
			<div className={styles.contacus}>
				<h1>¡Ponte en contacto y cotiza tu siguiente aplicación web!</h1>
				<ContactUsButton />
			</div>
			<br></br>
			<h2 className={styles.title}>Nuestros servicios web</h2>

			<div className={styles.swipperContainer}>
				<Swiper
					slidesPerView={3}
					spaceBetween={30}
					slidesPerGroup={3}
					loop={true}
					loopFillGroupWithBlank={true}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
					}}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						412: {
							slidesPerView: 1,
							spaceBetween: 20,
							slidesPerGroup: 1,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 30,
							slidesPerGroup: 2,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 30,
							slidesPerGroup: 3,
						},
					}}
					navigation={true}
					modules={[Autoplay, Pagination, Navigation]}
					className={styles.swiper}>
					<SwiperSlide className={styles.swiper_slide}>
						<div className={styles.carrouselContainer}>
							<img
								src="/assets/services/carrousel_cycle.png"
								className={styles.carrousel_img}
								alt="keeperImage"></img>
							<h2>Ciclo completo de diseño y desarrollo de paginas web</h2>
							<p>
								Creamos la pagina web desde cero, incluyendo la captura de
								requerimientos, diseño, implementación y aseguramiento de
								calidad, así como el mantenimiento y soporte.
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.swiper_slide}>
						<div className={styles.carrouselContainer}>
							<img
								src="/assets/services/carrousel_redesign.png"
								className={styles.carrousel_img}
								alt="keeperImage"></img>
							<h2>Rediseño </h2>
							<p>
								Si lo que deseas es portar tu website antiguo, con toda su
								información hacia una nueva y modera solución, lo realizamos
								brindándote una óptima experiencia de usuario.
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.swiper_slide}>
						<div className={styles.carrouselContainer}>
							<img
								src="/assets/services/carrousel_webapp.png"
								className={styles.carrousel_img}
								alt="keeperImage"></img>
							<h2>Desarrollo de aplicaciones web e integraciones </h2>
							<p>
								Si deseas enriquecer las capacidades de tu pagina web con
								funciones como: comunicaciones en tiempo real, sistemas de
								pagos, métricas, analíticas, entre otras formas de incrementar
								la participación de tus clientes.
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.swiper_slide}>
						<div className={styles.carrouselContainer}>
							<img
								src="/assets/services/carrousel_cloud.png"
								className={styles.carrousel_img}
								alt="keeperImage"></img>
							<h2>Migración a la nube </h2>
							<p>
								Migramos tus existentes aplicaciones web hacia servicios en la
								nube como Amazon Web Services - AWS, Google Cloud y otros. Los
								servicios de la nube mejoran tu escalabilidad, administración y
								reducen costos en infraestructura.
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.swiper_slide}>
						<div className={styles.carrouselContainer}>
							<img
								src="/assets/services/carrousel_maintenance.png"
								className={styles.carrousel_img}
								alt="keeperImage"></img>
							<h2>Mantenimiento y soporte </h2>
							<p>
								Adicionamos nuevas funcionalidades y resolvemos bugs, mejoramos
								la disponibilidad, escalabilidad y desempeño de tu aplicación,
								para que soporte un creciente numero de visitantes y datos.
								Mejoramos el SEO para complementar tus estrategias de Marketing.
							</p>
						</div>
					</SwiperSlide>
					<SwiperSlide className={styles.swiper_slide}>
						<div className={styles.carrouselContainer}>
							<img
								src="/assets/services/carrousel_mobile.png"
								className={styles.carrousel_img}
								alt="keeperImage"></img>
							<h2>Desarrollo enfocado en dispositivos móviles </h2>
							<p>
								Adaptamos tus paginas web hacia dispositivos mobiles como
								celulares y tablets de todas las plataformas y tamaños. Esto
								permite que tu solución este disponible en todo tipo de
								dispositivos.
							</p>
						</div>
					</SwiperSlide>
				</Swiper>
				<br></br>
			</div>
		</>
	);
}

export default WebSystems;
