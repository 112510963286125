import { Box, Container, Grid } from "@mui/material";
import React from "react";
import styles from "./MovilesPage.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function MovilesPage() {
  return (
    <div className={styles.layout}>
      <Box className={styles.header}>
        <div className={styles.tittle_container}>
          <h1>Aplicativos móviles</h1>
        </div>
      </Box>

      <Container maxWidth="lg">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={6}>
            <img
              src="/assets/mobile/side.png"
              className={styles.img_header}
              alt="keeperImage"
            ></img>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={styles.content}>
              <h2>Flutter</h2>
              <p>
                <CheckCircleIcon fontSize="small" sx={{ pt: 0.6 }} />
                Teto de placeholder para contenido lorem to de placeholder para
                contenido lorem
                <br /> <CheckCircleIcon fontSize="small" sx={{ pt: 0.6 }} />
                Teto de placeholder para contenido lorem to de placeholder para
                contenido lorem
                <br /> <CheckCircleIcon fontSize="small" sx={{ pt: 0.6 }} />
                Teto de placeholder para contenido lorem to de placeholder para
                contenido lorem
                <br /> <CheckCircleIcon fontSize="small" sx={{ pt: 0.6 }} />
                Teto de placeholder para contenido lorem to de placeholder para
                contenido lorem
                <br /> <CheckCircleIcon fontSize="small" sx={{ pt: 0.6 }} />
                Teto de placeholder para contenido lorem to de placeholder para
                contenido lorem
                <br /> <CheckCircleIcon fontSize="small" sx={{ pt: 0.6 }} />
                Teto de placeholder para contenido lorem to de placeholder para
                contenido lorem
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default MovilesPage;

/*
<br />
*/
