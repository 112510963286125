import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CardComponent from "./../common/CardComponent";
import useWindowPosition from "../../hook/useWindowPosition";
import Grow from "@mui/material/Grow";
import { useTranslation } from "react-i18next";
import styles from "./SystemsSection.module.css";

function SystemsSection() {
  const [checked, setChecked] = useState(false);
  const [checkedtwo, setCheckedtwo] = useState(false);
  const checkedAnimation = useWindowPosition("header", 0.04);
  const checkedAnimation2 = useWindowPosition("header", 0.32);

  const { t } = useTranslation();

  const data = {
    mobile: {
      image: "/assets/mobile.png",
      icon: "/assets/icon_phone.png",
      navigation: "/services",
    },
    front: {
      image: "/assets/web.png",
      icon: "/assets/icon_database.png",
      navigation: "/services",
    },
  };

  useEffect(() => {
    setChecked(true);
    setCheckedtwo(true);
  }, []);

  return (
    <Box
      id="systems"
      sx={{
        zIndex: 4,
        width: "100%",
        //backgroundImage: `url(${"images/wallpaper/main.png"})`,
        position: "relative", //"absolute"
        //opacity: [0.8, 0.9, 0.9],
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#EBF1F4", // EBF1F4
        pt: {
          sm: "20px",
          xs: "10px",
        },
        pr: {
          lg: "0px",
          md: "0px",
          sm: "15px",
          xs: "15px",
        },
        pl: {
          lg: "0px",
          md: "0px",
          sm: "15px",
          xs: "15px",
        },
      }}
    >
      <h1 className={styles.heading}>{t("main.categoryText")}</h1>
      <Grid container spacing={4}>
        <Grow in={checked} {...(checked ? { timeout: 1500 } : {})}>
          <Grid item xs={12} sm={12} md={6} sx={{ p: 0, m: 0 }}>
            <CardComponent
              data={data.mobile}
              title={t("main.apps")}
              description={t("main.appsDescription")}
              link={t("main.appsLink")}
              isReverse={true}
            />
          </Grid>
        </Grow>
        <Grow in={checkedtwo} {...(checkedtwo ? { timeout: 1500 } : {})}>
          <Grid item xs={12} sm={12} md={6} sx={{ p: 0, m: 0 }}>
            <CardComponent
              data={data.front}
              title={t("main.systems")}
              description={t("main.systemsDescription")}
              link={t("main.systemsLink")}
              isReverse={false}
            />
          </Grid>
        </Grow>
      </Grid>
    </Box>
  );
}

export default SystemsSection;
