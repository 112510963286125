import { Box, Container } from "@mui/material";
import React from "react";
import styles from "./NotFoundPage.module.css";

function NotFoundPage() {
  return (
    <Box
      minHeight="80vh"
      sx={{
        width: "100%",
        backgroundColor: "#EBF1F4",
        objectPosition: "center",
        position: "relative",
      }}
    >
      <Container className={styles.container} maxWidth="lg">
        <img src={"/assets/404.png"} className={styles.image} alt=""></img>
        <h1 className={styles.text}>
          Al parecer la pagina que buscas no se encuentra en nuestro servidor,
          prueba regresando a la página principal.
        </h1>
      </Container>
    </Box>
  );
}

export default NotFoundPage;
