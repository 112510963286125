import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			en: {
				translation: {
					navbar: {
						option1: "Home",
						option2: "Services",
						option3: "Sector",
						option4: "About",
						option5: "Contact",
					},
					footer: {
						option1: "Home",
						option2: "Services",
						option3: "Contact",
						option4: "Terms of use",
						option5: "Privacy policy",
					},
					main: {
						descripcion:
							"We focus on solving complex problems, making use of our passion for technology",
						button: "Contact us",

						apps: "App development",
						appsDescription:
							"We build mobile applications for both platforms Android and IOS, using technologies such as Flutter and others",
						appsLink: "➜ Mobile development",
						systems: "Systems",
						systemsDescription:
							"We use the most recent and popular technologies in the market such as React, Anbular, Nest, and databases like MongoDB and SQL",
						systemsLink: "➜ Front-end y Back-end",

						industry: "Mining and Indutry",
						industryDescription:
							"We offer consultancy and technological solutions for private and public institutions",
						industriLink: "➜ Our projects",

						inmobilaria: "Real state",
						inmobilariaDescription:
							"Solutions for real state industry, including automatizacion and managing real state buildings",
						inmobilariaLink: "➜ Real state",

						goverment: "Government",
						govermentDescription:
							"We provide solutions for public institutions, including Delta Dispatch, our solution for enhancing public safety ",
						govermentLink: "➜ View Delta Dispatch",

						categoryText: "Services",
						sectorText: "Sectors",
					},
					servicesTitle: "INTEGRAL SOLUTIONS DEVELOPMENT SERVICE",
					servicesDescription:
						"We help design and build superior digital products, establishing an optimal user experience on all modern platforms and devices.",
					servicesSubTitle: "OUR SERVICES",
					servicesTools: "OUR TOOLS",
					services: [
						{
							title: "Development of mobile applications",
							description:
								"We develop solutions for mobile devices using both native and hybrid technologies for iOS and Android systems.",
							icon: "/assets/services/appdevelopment.png",
							link: "/development",
						},
						{
							title: "Development of Back-end Solutions",
							description:
								"Every robust web application has a Back-end service, we provide server-side software development services.",

							icon: "/assets/services/backend.png",
							link: "/webdevelopment",
						},
						{
							title: "Web Systems Development",
							description:
								"We design and develop web applications that solve complex problems quickly and easily.",
							icon: "/assets/services/webdevelopment.png",
							link: "/web",
						},
						{
							title: "Integration of Systems and Solutions",
							description:
								"If you have old systems that you want to enhance or integrate new functions, we take care of the whole process.",

							icon: "/assets/services/systems.png",
							link: "/integration",
						},
						{
							title: "QA: Testing and Quality Assurance",
							description:
								"If you find that your application is not behaving properly, our testing and quality assurance service is for you.",
							icon: "/assets/services/testing.png",
							link: "/quality",
						},
						{
							title: "Support and maintenance",
							description:
								"Once your application is deployed, we provide support and maintenance so that your services are always available.",
							icon: "/assets/services/maintenance.png",
							link: "/maintenance",
						},
					],
					industry: {
						title: "DEVELOPMENT FOR INDUSTRY AND MINING",
						description:
							"On ITC we focus on digitalizing and integrating software solutions into existing industry processes, we take care of all phases, from design to integration.",
						bodyHeader: "SOFTWARE SOLUTIONS FOR INDUSTRY",
						bodyTitle: "Development of industrial solutions",
						body: "We help companies from different industries to reinvent themselves and stay ahead. Developing IoT solutions and bringing technology closer to industrial operations. We make use of measurement techniques and data recording systems to provide our clients with the most up-to-date information available.",
						contact: "¡Contact us and request a sales quotation!",
					},
					realstate: {
						title: "REAL ESTATE SOLUTIONS",
						description:
							"We provide solutions for real estate buildings, integrating business administration and property management with IoT services to give you full control, anytime and anywhere.",
						bodyHeader: "IT SOLUTIONS FOR BUILDINGS",
						bodyTitle:
							"Systems for administration of buildings and condominiums",
						body: "We contribute to your family well-being through a proper building management system. Adding agility and technology to your administrative plans, we provide everything you need to stay calm at home. We provide specialized software for buildings and condominium management.",
						contact: "¡Contact us and request a sales quotation!",
						carrouselTitle: "Advantages of our system",
						carrouselDescription:
							"Our solution allows you to comprehensively manage your building and aspects such as maintenance with all its systems, services including suppliers, among others.",
						card1Title: "Simplified administration",
						card1Description:
							"Because we do things differently, you will know everything that happens in your building from anywhere.",
						card2Title: "Easy to customize",
						card2Description:
							"Configure everything tailored to your building or condominium (Bank Accounts, Telephones, news, etc.)",
						card3Title: "Scheduled notification notices with zero cost",
						card3Description:
							"Notify your slow payer residents with little effort.",
						card4Title: "Immediate monthly balance",
						card4Description:
							"Monthly balance could not have been easier to generate and publish.",
						card5Title: "Transparency, communication and continuity",
						card5Description:
							"Detailed online information on the administrative, financial and maintenance management of the building.",
						card6Title: "Guaranteed security",
						card6Description:
							"Monitoring of personnel, installation of cameras and biometrics. We advise you to achieve cost efficiency in your building.",
					},
					about: {
						aboutUS: "About us",
						aboutUSdescription:
							"ITC is a company that arises with the ideal of making life easier for people and companies, our passion for technology allows us to deliver innovative solutions to complex problems.",
						values: "Values ​​and philosophy",
						valuesDescription:
							"At ITC we cultivate an agile culture which makes us accept change. This is the only constant in all our activities and allows us to deliver solutions quickly and with the ability to evolve over time. We are a professional company that promotes change, diversity and professionalism in everything we do, our pillars are:",
						value1: "Commitment",
						value2: "Professionalism",
						value3: "Creativity",
					},
					contact: {
						title: "Contact us",
						description:
							"If you have any questions, fill out the following form and we will contact you.",
						invitation: "You don't like forms? Write us by email.",
						email: "innova.msg@gmail.com",
						cellphone: "+51 946066646",
						formTitle: "Contact Form",
						formPlaceholder1: "Ask us a question",
						formPlaceholder2: "Email",
						formPlaceholder3: "Full name",
						sendButton: "Send",
					},
				},
			},
			es: {
				translation: {
					navbar: {
						option1: "Inicio",
						option2: "Servicios",
						option3: "Sectores",
						option4: "Nosotros",
						option5: "Contáctenos",
					},
					footer: {
						option1: "Inicio",
						option2: "Servicios",
						option3: "Contáctenos",
						option4: "Términos de uso",
						option5: "Política de privacidad",
					},
					main: {
						descripcion:
							"Nos enfocamos en solucionar problemas complejos, haciendo uso de nuestra pasión por la tecnologia",
						button: "Contáctanos",
						apps: "Aplicativos móviles",
						appsDescription:
							"Desarrollamos soluciones móviles tanto para plataformas iOS y Android utilizando tecnologias hybridas y nativas.",
						appsLink: "➜ Desarrollo móvil",
						systems: "Sistemas",
						systemsDescription:
							"Usamos las tecnologias más recientes en el mercado como React, Angular, Nest y bases de datos relacionales y no relacionales.",
						systemsLink: "➜ Front-end y Back-end",

						industry: "Industria y mineria",
						industryDescription:
							"Si desea digitalizar sus procesos o integrar soluciones de software a procesos existentes nosotros nos encargamos del diseño e integración.",
						industriLink: "➜ Información complementaria.",

						inmobilaria: "Inmobiliaria",
						inmobilariaDescription:
							"Brindamos soluciones para el sector inmobiliario, integrando aspectos de administración con la gestión de inmuebles de manera rápida y sencilla.",
						inmobilariaLink: "➜ Información complementaria",

						goverment: "Sector Público",
						govermentDescription:
							"Tenemos experiencia creando soluciones para el sector público. Entre algunas de nuestras soluciones esta Delta Dispatch, nuestra solución para promover la seguridad ciudadana.",
						govermentLink: "➜ Visite Delta Dispatch",
						categoryText: "Servicios",
						sectorText: "Sectores",
					},
					servicesTitle: "SERVICIO DE DESARROLLO DE SOLUCIONES INTEGRALES",
					servicesDescription:
						"Ayudamos a diseñar y construir productos digitales superiores, estableciendo una óptima experiencia de usuario en todas las plataformas y dispositivos modernos.",
					servicesSubTitle: "NUESTROS SERVICIOS",
					servicesTools: "NUESTRAS HERRAMIENTAS",
					services: [
						{
							title: "Desarrollo de aplicativos móviles",
							description:
								"Desarrollamos soluciones para dispositivos móviles utilizando tanto tecnologias nativas como híbridas para sistemas iOS y Android.",
							icon: "/assets/services/appdevelopment.png",
							link: "/development",
						},
						{
							title: "Desarrollo de Soluciones Back-end",
							description:
								"Toda aplicacion web robusta cuenta con un servicio de Back-end, brindamos servicios de desarrollo de software del lado del servidor.",

							icon: "/assets/services/backend.png",
							link: "/webdevelopment",
						},
						{
							title: "Desarrollo de Sistemas Web",
							description:
								"Diseñamos y desarrollamos aplicaciones web que solucionen problemas conplejos de manera rápida y sencilla.",

							icon: "/assets/services/webdevelopment.png",
							link: "/web",
						},
						{
							title: "Integracion de Sistemas y Soluciones",
							description:
								"Si cuenta con sistemas antiguos que quiere potenciar o integrar nuevas funciones, nosotros nos encargamos de todo el proceso.",

							icon: "/assets/services/systems.png",
							link: "/integration",
						},
						{
							title: "QA: Pruebas y Aseguramiento de Calidad",
							description:
								"Si percibe que su aplicación no se comporta adecuadamente, nuestro servicio de pruebas y aseguramiento de calidad es para usted.",
							icon: "/assets/services/testing.png",
							link: "/quality",
						},
						{
							title: "Soporte y Mantenimiento",
							description:
								"Una vez desplegado su aplicativo, le brindamos soporte y mantenimiento para que sus servicios esten siempre disponibles.",
							icon: "/assets/services/maintenance.png",
							link: "/maintenance",
						},
					],
					industry: {
						title: "DESARROLLO PARA LA INDUSTRIA Y MINERIA",
						description:
							"Nos encargamos de digitalizar procesos o integrar soluciones de software a procesos existentes, nosotros nos encargamos de todas las fases, desde el diseño hasta la integración.",
						bodyHeader: "SOLUCIONES DE SOFTWARE PARA LA INDUSTRIA",
						bodyTitle: "Desarrollo de soluciones industriales",
						body: "Ayudamos a empresas de distintas industrias a reinventarse y mantenerse a la vanguardia. Desarrollamos soluciones de IoT para acercar la tecnología a las operaciones industriales remotas. Hacemos posible la medición y registro de datos y el posterior envío de la información hacia el operador del sistema. De esta manera, la información recabada por diferentes dispositivos electrónicos, puede ser monitoreada y administrada y enviada en tiempo real a un centro de control.",
						contact: "¡Ponte en contacto y solicita una cotización!",
					},
					realstate: {
						title: "SOLUCIONES INMOBILARIAS",
						description:
							"Brindamos soluciones para el sector inmobiliario, integrando aspectos de administración y gestión de inmuebles con aspectos informáticos para tener el control desde cualquier dispositivo y lugar.",
						bodyHeader: "SOLUCIONES INFORMÁTICAS PARA EDIFICIOS",
						bodyTitle:
							"Software especializado en la administración eficiente de edificios y condominios",
						body: "Contribuimos al bienestar familiar mediante una gestión adecuada en su edificio o condominio. A la gestión administrativa le unimos agilidad y tecnología, con lo cual tienes todo para que estés tranquilo en tu hogar. Brindamos software especializado para gestión de edificios y condominios. Soporte administrativo y técnico a negocios y empresas.",
						contact: "¡Ponte en contacto y solicita una cotización!",
						carrouselTitle: "Ventajas de nuestro sistema",
						carrouselDescription:
							"Nuestra solución te permite gestionar de manera integral tu edificio, aspectos como mantenimiento y todos sus sistemas, servicios incluyendo proveedores, entre otros.",
						card1Title: "Administración sencilla",
						card1Description:
							"Porque hacemos las cosas diferente, sabrás todo lo que ocurre en tu edificio desde cualquier lugar.",
						card2Title: "Fácil de personalizar ",
						card2Description:
							"Configura todo a medida de tu edificio o condominio (Cuentas Bancarias, Teléfonos, noticias,etc.)",
						card3Title: "Avisos programados de cobranza con costo cero",
						card3Description:
							"Notifica a tus residentes morosos con poco esfuerzo.",
						card4Title: "Balance mensual inmediato ",
						card4Description:
							"El Balance del mes no puede ser más fácil de generar y queda listo para publicar.",
						card5Title: "Transparencia, comunicación y continuidad ",
						card5Description:
							" Información detallada en línea sobre la gestión administrativa, financiera y mantenimiento del edificio.",
						card6Title: "Seguridad garantizada",
						card6Description:
							"    Seguimiento de personal, instalación de cámaras y biometría. Te asesoramos para lograr eficiencia en costos de tu Edificio.",
					},
					about: {
						aboutUS: "Quíenes somos",
						aboutUSdescription:
							"ITC es una empresa que surge con el ideal de facilitar la vida a las personas y empresas, nuestra pasión por la tecnologia nos permite entregar soluciones novedosas a problemas complejos.",
						values: "Valores y filosofía",
						valuesDescription:
							"En ITC cultivamos una cultura ágil la cual nos hace aceptar el cambio. Esta es la única constante en todas nuestras actividades y nos permite entregar soluciones de manera rapida y con la capacidad de evolucionar en el tiempo. Somos una empresa profesional que promueve el cambio, la diversidad y la profesionalidad en todo lo que hacemos, nuestros pilares son:",
						value1: "Compromiso",
						value2: "Profesionalismo",
						value3: "Creatividad",
					},
					contact: {
						title: "Contáctanos",
						description:
							"Si tiene alguna consulta rellene el siguiente formulario y nos pondremos en contacto.",
						invitation:
							"¿No te gustan los formularios? Escríbenos al correo electrónico.",
						email: "proyectos@intelcongroup.com",
						cellphone: "+51 946066646",
						formTitle: "Formulario de contacto",
						formPlaceholder1: "Realizanos una pregunta",
						formPlaceholder2: "Correo electrónico",
						formPlaceholder3: "Nombres",
						sendButton: "Enviar",
					},
				},
			},
		},
		fallbackLng: "es",
		debug: true,
		interpolation: {
			escapeValue: false, // not needed for react!!
		},
	});

export default i18n;
