import React from "react";
import styles from "./TextListComponent.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function TextListComponent(props) {
  return (
    <div className={styles.container}>
      {props.data.map((item) => (
        <div className={styles.textRow} key={item.id}>
          <CheckCircleIcon fontSize="small" sx={{ pt: 0.7, m: 0, pb: 0 }} />

          <p>{item.description}</p>
        </div>
      ))}
    </div>
  );
}

export default TextListComponent;
