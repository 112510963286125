import React from "react";
import { Link } from "react-router-dom";
import styles from "./WhiteCard.module.css";

function WhiteCard(props) {
  return (
    <Link to={props.data.link} className={styles.flex_card}>
      <img
        src={props.data.icon}
        className={styles.icon_image}
        alt="iconimage"
      ></img>
      <div className={styles.columnContainer}>
        <h2>{props.data.title}</h2>
        <p>{props.data.description}</p>
        <p className={styles.link}>➜ VER MÁS</p>
      </div>
    </Link>
  );
}

export default WhiteCard;
