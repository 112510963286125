import { Box, Container } from "@mui/material";
import React from "react";
import styles from "./Footer.module.css";
import { ImInstagram, ImFacebook } from "react-icons/im";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
	const { t } = useTranslation();

	return (
		<Box
			minHeight="25vh"
			id="footer"
			sx={{
				width: "100%",
				//backgroundImage: `url(${"images/wallpaper/main.png"})`,
				position: "relative",
				//opacity: [0.8, 0.9, 0.9],
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundColor: "#051120", //051120
				boxShadow: "0px -3px 14px 8px rgba(0, 0, 0, 0.1)",
				zIndex: 7,
			}}>
			<Box height="50px"></Box>

			<Container className={styles.footerContainer} maxWidth="xs">
				<Avatar
					sx={{
						display: "inline-flex",
						bgcolor: "#BDBDBD",
						"&:hover": {
							bgcolor: "#F4E9E1",
						},
					}}
					className={styles.iconContainer}
					component={Link}
					to="/">
					<ImInstagram className={styles.icon} />
				</Avatar>
				<Avatar
					component={Link}
					to="/"
					sx={{
						display: "inline-flex",
						bgcolor: "#BDBDBD",
						"&:hover": {
							bgcolor: "#F4E9E1",
						},
					}}
					className={styles.iconContainer}>
					<ImFacebook className={styles.icon} />
				</Avatar>
			</Container>
			<Box height="20px"></Box>
			<Container className={styles.footerContainer} maxWidth="xl">
				<Link className={styles.link_underline} to="/">
					<h1 className={styles.footerText}>{t("footer.option1")}</h1>
				</Link>

				<h1 className={styles.reservados}>•</h1>
				<Link className={styles.link_underline} to="/#systems">
					<h1 className={styles.footerText}>{t("footer.option2")}</h1>
				</Link>
				<h1 className={styles.reservados}>•</h1>
				<Link className={styles.link_underline} to="/#industry">
					<h1 className={styles.footerText}>{t("footer.option3")}</h1>
				</Link>
			</Container>
			<Container className={styles.footerContainer} maxWidth="xl">
				<Link className={styles.link_underline} to="/about">
					<h1 className={styles.footerText}>{t("footer.option4")}</h1>
				</Link>
				<h1 className={styles.reservados}>•</h1>
				<Link className={styles.link_underline} to="/contacts">
					<h1 className={styles.footerText}>{t("footer.option5")}</h1>{" "}
				</Link>
			</Container>
			<Container className={styles.footerContainer} maxWidth="xl">
				<h1 className={styles.reservados}>©2024 ITC</h1>
			</Container>
		</Box>
	);
}

export default Footer;
