import React, { useState } from "react";
import styles from "./FrameworkCard.module.css";
import Modal from "react-modal";

//Works without it
Modal.setAppElement("#root");

function FrameworkCard(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* <Link className={styles.flex_card} component="a" to="/">   </Link> */}
      <button className={styles.flex_card} onClick={toggleModal}>
        <img
          src={props.data.img} //"./assets/placeholder.png"
          className={styles.icon_image}
          alt="iconimage"
        ></img>
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        ariaHideApp={false}
        className={styles.modal}
      >
        <div className={styles.modal_content}>
          <h3>{props.data.title}</h3>
          <p>{props.data.content}</p>
          <br></br>
          <button className={styles.modal_close_button} onClick={toggleModal}>
            Regresar
          </button>
        </div>
      </Modal>
    </>
  );
}

export default FrameworkCard;
