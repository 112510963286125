import React from "react";
import ContactUsButton from "../common/ContactUsButton";
import styles from "./AppDevelopment.module.css";

export default function AppDevelopment() {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.textWrapper}>
					<h2>SERVICIO DE DESARROLLO DE APPS PARA iOS Y ANDROID</h2>
					<p>
						Ofrecemos soluciones completas basadas en tecnología móvil tanto
						para dispositivos iOS y android.
					</p>
				</div>
			</div>

			<div className={styles.content}>
				<div>
					<br></br>
					<h4 className={styles.contentColor}>
						DESARROLLAMOS APLICATIVOS DE CALIDAD
					</h4>
					<h3> Desarrollo de aplicaciones para empresas</h3>
					<p>
						En ITC somos expertos en el desarrollo de aplicaciones móviles para
						empresas. Utilizamos las más recientes tecnologias en los proyectos
						de nuestros clientes. Somos conscientes del auge que vive
						actualmente el sector de las aplicaciones móviles y el aumento de su
						uso en celulares inteligentes y tablets. Es por ello que nuestro
						desarrollo sigue la filosofía Mobile First. Que pone el foco en los
						teléfonos móviles y los adapta para diferentes tipos de pantalla y
						dispositvos como tablets y demás.
					</p>
				</div>
				<img
					src="/assets/services/appImage.png"
					className={styles.img}
					alt="keeperImage"></img>
			</div>

			<div className={styles.appprocessdevelopment}>
				<h2>Proceso de desarrollo de una app móvil</h2>
				<p>
					Nos encargamos de todas las etapas, prestamos gran atención a la
					calidad del código y garantizamos el mantenimiento y futuras
					actualizaciones.
				</p>
				<br></br>

				<div className={styles.sectionapplifecycle}>
					<div className={styles.columnlifeapp}>
						<img
							src="/assets/services/lifeplanning.png"
							className={styles.iconimg}
							alt="keeperImage"></img>
						<h3>Planificación</h3>
						<p>
							Nos encargamos de estudiar y planificar tu idea, te asesoramos y
							nos adaptamos a lo que necesitas.
						</p>
					</div>

					<div className={styles.columnlifeapp}>
						<img
							src="/assets/services/lifedesign.png"
							className={styles.iconimg}
							alt="keeperImage"></img>
						<h3>Diseño</h3>
						<p>
							Creamos soluciones móviles con un diseño adecuado a tu proyecto,
							compatibles con cualquier dispositivo y ofreciendo la mejor
							experiencia de usuario.
						</p>
					</div>

					<div className={styles.columnlifeapp}>
						<img
							src="/assets/services/lifedevelopment.png"
							className={styles.iconimg}
							alt="keeperImage"></img>
						<h3>Desarrollo</h3>
						<p>
							Desarrollamos aplicaciones móviles adaptadas a cada cliente, al
							sector y a las necesidades del proyecto. Te asesoramos en todo el
							proceso.
						</p>
					</div>

					<div className={styles.columnlifeapp}>
						<img
							src="/assets/services/liferelease.png"
							className={styles.iconimg}
							alt="keeperImage"></img>
						<h3>Lanzamiento</h3>
						<p>
							Tras la fase de testing y aprobación, publicamos tu aplicación en
							Google Play y App Store para que esté disponible para cualquier
							usuario.
						</p>
					</div>
				</div>
			</div>

			<div className={styles.cardSectionContainer}>
				<h2>El tipo de app que tu empresa necesita</h2>

				<div className={styles.cardSectionGrid}>
					<div className={styles.cardsmall}>
						<h3>APP NATIVA</h3>
						<p>
							Son aquellas aplicaciones que se desarrollan con un software
							especifico para cada sistema operativo, pueden ser iOS o Android
							utilizando Swift o Kotlin respectivamente
						</p>
					</div>
					<div className={styles.cardsmall}>
						<h3>APP HÍBRIDA</h3>
						<p>
							Son aquellas aplicaciones que se desarrollan con una única base de
							código que funciona tanto para Android y iOS y se comportan como
							una app nativa, podemos utilizar tanto Flutter como React Native
						</p>
					</div>
				</div>
			</div>

			<div className={styles.contacus}>
				<h1>¡Ponte en contacto y comenzaremos a crear tu app!</h1>
				<ContactUsButton />
			</div>
		</>
	);
}
