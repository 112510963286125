import React from "react";
import { Box } from "@mui/system";
import SystemsSection from "./SystemsSection";
import IndustrySection from "./IndustrySection";
import MainSection from "./MainSection";
import styles from "./Homepage.module.css";
import { useTranslation } from "react-i18next";

function Homepage() {
  const { t } = useTranslation();

  return (
    <>
      <Box
        id="header"
        sx={{
          width: "100%",
          position: "relative",
          //opacity: [0.8, 0.9, 0.9],
          backgroundRepeat: "no-repeat",
          backgroundColor: "#EBF1F4",
          objectFit: "cover",
          objectPosition: "center",
        }}
      >
        <MainSection />
      </Box>
      <div className={styles.wrapper}>
        <div className={styles.body}>
          <SystemsSection />

          <IndustrySection />
        </div>
        <br></br>
      </div>
    </>
  );
}

export default Homepage;

/*
      <Box height="56px" sx={{ backgroundColor: "#EBF1F4" }}></Box>
*/
