import React from "react";
import ContactUsButton from "../common/ContactUsButton";
import styles from "./MaintenancePage.module.css";

function MaintenancePage() {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.textWrapper}>
					<h2>SOPORTE Y MANTENIMIENTO</h2>
					<p>
						Brindamos soporte en operaciones y mantenimiento de sistemas, una
						vez que su aplicativo se encuentra disponible para los usuarios
						finales, brindamos asistencia técnica y mantenimiento de sistema en
						un mediano y largo plazo.
					</p>
				</div>
			</div>

			<div className={styles.content}>
				<div>
					<br></br>
					<h4 className={styles.contentColor}>
						Servicios de soporte y mantenimiento de sistemas
					</h4>
					<h3> Servicio de soporte y mantenimiento de sistemas</h3>
					<p>
						En ITC nuestro compromiso va más allá del desarrollo de soluciones
						informáticas, brindamos cobertura para nuestros clientes, ofreciendo
						planes de soporte extendido en los productos desarrollados y
						mantenimiento de las aplicaciones para que se mantengan operativas a
						lo largo del tiempo, incluyendo, mejoras y funcionalidades
						adicionales.
					</p>
				</div>
				<img
					src="/assets/services/supportImage.png"
					className={styles.img}
					alt="keeperImage"></img>
			</div>
			<div className={styles.contacus}>
				<h1>¡Ponte en contacto y solicita una cotización!</h1>
				<ContactUsButton />
			</div>
		</>
	);
}

export default MaintenancePage;
