import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./HomePageView/Homepage";
import NotFoundPage from "./NotFoundPage";
import Contacts from "./Contacts";
import { ThemeProvider } from "@mui/material";
import theme from "../theme/InnovaTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "./Layout";
import PublicsectorPage from "./PublicsectorPage";
import MovilesPage from "./MovilesPage";
import SolucionesPage from "./SolucionesPage";
import ScrollToTop from "../hook/ScrollToTop";
import ServicesPage from "./ServicesPage";
import AppDevelopment from "./ServicesSection/AppDevelopment";
import BackendDevelopment from "./ServicesSection/BackendDevelopment";
import WebSystems from "./ServicesSection/WebSystems";
import SystemsIntegration from "./ServicesSection/SystemsIntegration";
import TestingPage from "./ServicesSection/TestingPage";
import MaintenancePage from "./ServicesSection/MaintenancePage";
import About from "./About";
import IndustryPage from "./Industry/IndustryPage";
import RealStatePage from "./Industry/RealStatePage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <CssBaseline />
          <ScrollToTop />
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/" exact element={<Homepage />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/public" element={<PublicsectorPage />} />
            <Route path="/movile" element={<MovilesPage />} />
            <Route path="/solutions" element={<SolucionesPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/development" element={<AppDevelopment />} />
            <Route path="/webdevelopment" element={<BackendDevelopment />} />
            <Route path="/web" element={<WebSystems />} />
            <Route path="/integration" element={<SystemsIntegration />} />
            <Route path="/quality" element={<TestingPage />} />
            <Route path="/maintenance" element={<MaintenancePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/industry" element={<IndustryPage />} />
            <Route path="/state" element={<RealStatePage />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;

/*
  <ThemeProvider theme={theme}>
  </ThemeProvider>
*/

/*
    <>
    <Header />
      <div className={classes.toolbar}> </div>
     <Routes>
     <Route path="*" element={<NotFoundPage />} />
     <Route path="/" exact element={<Homepage />} />
     <Route path="/contacts" element={<Contacts />} />
   </Routes>

   <Footer />
 </>
*/
