import React from "react";
import ContactUsButton from "../common/ContactUsButton";
import styles from "./TestingPage.module.css";

function TestingPage() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <h2>PRUEBAS Y ASEGURAMIENTO DE CALIDAD</h2>
          <p>
            Si cuentas con proyectos de software que no se desempeñan de forma
            adecuada, podemos revisar tu sistema y darte un diagnóstico.
            Contamos con un set de pruebas que medirán su aplicación en
            diferentes frentes.
          </p>
        </div>
      </div>

      <div className={styles.content}>
        <div>
          <br></br>
          <h4 className={styles.contentColor}>
            APLICACIONES CON ALTOS ESTANDARES DE CALIDAD Y PRUEBAS
          </h4>
          <h3> Pruebas y calidad en nuestras soluciones</h3>
          <p>
            Nuestros servicios de aseguramiento de calidad te permitiran conocer
            de forma precisa la calidad de tu software, utilizamos las últimas
            tendencias para asegurar un rendimiento óptimo en la implementación
            de sus proyectos. Nuestros expertos en poco tiempo podrán validar su
            sistema a nivel: funcional, de integración, rendimiento, usabilidad
            y seguridad.
          </p>
        </div>
        <img
          src="/assets/services/testImage.png"
          className={styles.img}
          alt="keeperImage"
        ></img>
      </div>

      <div className={styles.contacus}>
        <h1>¡Ponte en contacto y solicita una cotización!</h1>
        <ContactUsButton />
      </div>

      <br></br>
      <h2 className={styles.title_text}>Tipos de test</h2>
      <p className={styles.text_header}>
        Te ofrecemos un set comprensivo de servicios de aseguramiento de calidad
        de software. Obtendrás tu aplicativo sin problemas, robusto y que cumpla
        con los requerimientos y metas de tu negocio.
      </p>

      <div className={styles.grid_container_test}>
        <div className={styles.grid_card_test}>
          <br></br>
          <h3>Test de usabilidad</h3>
          <p>
            Nuestro equipo revisa que tan intuitivo es su portal, aplicación web
            o sitio web hacia sus usuarios finales. Identificamos problemas de
            usabilidad y sugerimos mejoras para ayudarlo a mejorar su producto y
            incrementar su audiencia.
          </p>{" "}
          <br></br>
        </div>
        <div className={styles.grid_card_test}>
          <br></br>
          <h3>Test funcionales</h3>
          <p>
            Revisamos si su aplicación web funciona de acuerdo a la tecnologia y
            sus requerimientos de negocio y si estos reportan a sus usuarios
            designados. Todas las funcionalidades deben estar acorde a sus
            requerimientos y especificaciones del sistema.
          </p>{" "}
          <br></br>
        </div>
        <div className={styles.grid_card_test}>
          <br></br>
          <h3>Test de rendimiento</h3>
          <p>
            Nuestro equipo revisa si su solución web funciona bajo cierta
            cantidad definida de usuarios cargados en el sistema, pruebas de
            estres, carga de archivos, escalabilidad y disponibilidad de
            servicio.
          </p>{" "}
          <br></br>
        </div>
        <div className={styles.grid_card_test}>
          <br></br>
          <h3>Test de compatibilidad</h3>
          <p>
            Identificamos si su aplicación web o página web funciona
            correctamente en diferentes ambientes: for ejemplo, en diferentes
            dispositivos (laptops, tablets, dispositivos móviles, etc.) y
            tambien a través de diferentes sistemas operativos, diferentes
            navegadores y más.
          </p>
          <br></br>
        </div>
      </div>
      <br></br>
      <br></br>
    </>
  );
}

export default TestingPage;
