import React from "react";
import styles from "./IndustryPage.module.css";
import ContactUsButton from "../common/ContactUsButton";
import { useTranslation } from "react-i18next";

function IndustryPage() {


  const { t } = useTranslation();


  return (
    <>
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <h2>
            {t("industry.title")}
          </h2>
          <p>
            {t("industry.description")}
          </p>
        </div>
      </div>

      <div className={styles.content}>
        <div>
          <br></br>
          <h4 className={styles.contentColor}>
            {t("industry.bodyHeader")}
          </h4>
          <h3>  {t("industry.bodyTitle")}</h3>
          <p>
            {t("industry.body")}  </p>
        </div>
        <img
          src="/assets/sectors/mining_image.png"
          className={styles.img}
          alt="keeperImage"
        ></img>
      </div>
      <div className={styles.contacus}>
        <h1> {t("industry.contact")}</h1>
        <ContactUsButton />
      </div>
    </>
  );
}

export default IndustryPage;
