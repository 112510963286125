import React from "react";
import { Box } from "@mui/material";
import Header from "./common/Header";
import Footer from "./common/Footer";

function Layout({ children }) {
  return (
    <div>
      <Header />

      {/*
      <Box
        height={60}
        sx={{
          backgroundImage: `linear-gradient(90deg, rgba(244, 236, 230, 1) 0%, rgba(243, 210, 185, 1) 100%);`,
        }}
      ></Box>
      */}
      <Box sx={{ backgroundColor: "#EBF1F4" }}>{children}</Box>

      <Footer />
    </div>
  );
}

export default Layout;
