import React from "react";
import styles from "./SmallCardComponent.module.css";

function SmallCardComponent(props) {
  return (
    <div className={styles.flex_card}>
      <img
        src={props.data.icon}
        className={styles.icon_image}
        alt="iconimage"
      ></img>
      <h2>{props.data.title}</h2>

      <p>{props.data.description}</p>
    </div>
  );
}

export default SmallCardComponent;
