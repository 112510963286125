import React from "react";
import styles from "./ContactUsButton.module.css";
import { useTranslation } from "react-i18next";

export default function ContactUsButton(props) {
  const { t } = useTranslation();
  return (
    <a href="/contacts" className={styles.ecButton}>
      {t("main.button")}
    </a>
  );
}
