import React from "react";
import styles from "./PublicsectorPage.module.css";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function PublicsectorPage() {
	return (
		<>
			<div className={styles.body}>
				{/* First secction */}
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={6}>
						<img
							src="/assets/keeper/landing.png"
							className={styles.img_header}
							alt="webImage"></img>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<div className={styles.header_Content}>
							<h1 className={styles.text}> Delta Dispatch</h1>
							<h1 className={styles.subtitle}>
								Nuestra solución digital que permite reportar incidentes dentro
								de tú distrito y recibir ayuda en tiempo real.
							</h1>
							<a href="https://sosmuni.com/" className={styles.button}>
								Visita Delta Dispatch
							</a>
						</div>
					</Grid>
				</Grid>

				{/* Section 2 */}
				<h1 className={styles.text}> Ofrecemos </h1>
				<Grid container spacing={{ xs: 3, sm: 3, md: 4 }}>
					<Grid item xs={12} sm={6} md={4}>
						<div className={styles.benefit_sections}>
							<img
								src="/assets/keeper/call.png"
								className={styles.icon_image}
								alt="iconimage"></img>
							<h3>Centro de llamadas</h3>
							<p>
								Coordinacion centralizada para la resolución de sus incidencias.
							</p>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<div className={styles.benefit_sections}>
							<img
								src="/assets/keeper/monitoring.png"
								className={styles.icon_image}
								alt="iconimage"></img>
							<h3> Monitoreo en tiempo real</h3>
							<p>Transparencia y control desde cualquier lugar y dispositivo</p>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<div className={styles.benefit_sections}>
							<img
								src="/assets/keeper/vecinal.png"
								className={styles.icon_image}
								alt="iconimage"></img>
							<h3> Vecinal</h3>
							<p>Aplicación disponible para plataformas IOS y Android</p>
						</div>
					</Grid>
				</Grid>

				{/* Secction  3 */}
				<Box height="40px"></Box>
				<Grid container spacing={3} alignItems="center" justifyContent="center">
					<Grid item xs={12} sm={12} md={6}>
						<div className={styles.header_Content_2}>
							<h1 className={styles.text}> Ventajas de Delta Dispatch</h1>

							<p>
								<CheckCircleIcon fontSize="small" sx={{ pt: 0.5 }} /> Simplifica
								la gestión de las municipalidades para atender alertas y
								emergencias dentro de su jurisdicción.
							</p>
							<p>
								<CheckCircleIcon fontSize="small" sx={{ pt: 0.5 }} /> Mejora la
								atención y el tiempo de respuesta ante emergencias de la
								población.
							</p>
							<p>
								<CheckCircleIcon fontSize="small" sx={{ pt: 0.5 }} /> Permite
								reportar de manera remota problemas vecinales como el
								mantenimiento de parques, recojo de basura, etch.
							</p>

							<p>
								<CheckCircleIcon fontSize="small" sx={{ pt: 0.5 }} /> Apoya en
								la toma de decisiones brindando reportes de diversa índole.
							</p>
							<p>
								<CheckCircleIcon fontSize="small" sx={{ pt: 0.5 }} /> Es
								portátil, se puede gestionar el trabajo desde cualquier lugar.
							</p>
							<p>
								<CheckCircleIcon fontSize="small" sx={{ pt: 0.5 }} /> Permite a
								sus usuarios recibir actualizaciones sobre el estado de sus
								reportes generados, atendidos y resueltos.
							</p>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<img
							src="/assets/keeper/web.png"
							className={styles.img}
							alt="webImage"></img>
					</Grid>
				</Grid>
				<br></br>
			</div>
		</>
	);
}
