import React from "react";
import ContactUsButton from "../common/ContactUsButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import styles from "./RealStatePage.module.css";
import { useTranslation } from "react-i18next";


function RealStatePage() {

  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <h2>{t("realstate.title")}</h2>
          <p>
            {t("realstate.description")}
          </p>
        </div>
      </div>

      <div className={styles.content}>
        <div>
          <br></br>
          <h4 className={styles.contentColor}>
            {t("realstate.bodyHeader")}
          </h4>
          <h3> {t("realstate.bodyTitle")}</h3>
          <p>
            {t("realstate.body")}
          </p>
        </div>
        <img
          src="/assets/sectors/real_state_img.png"
          className={styles.img}
          alt="keeperImage"
        ></img>
      </div>
      <div className={styles.contacus}>
        <h1>{t("realstate.contact")}</h1>
        <ContactUsButton />
      </div>

      <br></br>
      <h2 className={styles.title}>{t("realstate.carrouselTitle")}</h2>
      <h2 className={styles.subtitle}>{t("realstate.carrouselDescription")}</h2>

      <div className={styles.swipperContainer}>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          slidesPerGroup={3}
          loop={true}
          loopFillGroupWithBlank={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            412: {
              slidesPerView: 1,
              spaceBetween: 20,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
              slidesPerGroup: 2,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
              slidesPerGroup: 3,
            },
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.carrouselContainer}>
              <img
                src="/assets/housing/administration.png"
                className={styles.carrousel_img}
                alt="keeperImage"
              ></img>
              <h2>{t("realstate.card1Title")}</h2>
              <p>
                {t("realstate.card1Description")}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.carrouselContainer}>
              <img
                src="/assets/housing/custom.png"
                className={styles.carrousel_img}
                alt="keeperImage"
              ></img>
              <h2>{t("realstate.card2Title")} </h2>
              <p>
                {t("realstate.card2Description")}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.carrouselContainer}>
              <img
                src="/assets/housing/programated.png"
                className={styles.carrousel_img}
                alt="keeperImage"
              ></img>
              <h2>{t("realstate.card3Title")}</h2>
              <p>
                {t("realstate.card3Description")}    </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.carrouselContainer}>
              <img
                src="/assets/housing/balance.png"
                className={styles.carrousel_img}
                alt="keeperImage"
              ></img>
              <h2>  {t("realstate.card4Title")} </h2>
              <p>
                {t("realstate.card4Description")}  </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.carrouselContainer}>
              <img
                src="/assets/housing/comunication.png"
                className={styles.carrousel_img}
                alt="keeperImage"
              ></img>
              <h2>{t("realstate.card5Title")} </h2>
              <p>
                {t("realstate.card5Description")}  </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper_slide}>
            <div className={styles.carrouselContainer}>
              <img
                src="/assets/housing/secutiry.png"
                className={styles.carrousel_img}
                alt="keeperImage"
              ></img>
              <h2>{t("realstate.card6Title")} </h2>
              <p>
                {t("realstate.card6Description")}  </p>
            </div>
          </SwiperSlide>

        </Swiper>
        <br></br>
      </div>
    </>
  );
}
export default RealStatePage;
