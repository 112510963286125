import React from "react";
import ContactUsButton from "../common/ContactUsButton";
import styles from "./SystemsIntegration.module.css";

function SystemsIntegration() {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.textWrapper}>
					<h2>INTEGRACIÓN DE SISTEMAS Y SOLUCIONES</h2>
					<p>
						Si en la actualidad cuenta con un sistema y desea potenciar sus
						capacidades, agregando funcionalidades, integrándolo con sistemas de
						terceros o propios, esta es su opción.
					</p>
				</div>
			</div>

			<div className={styles.content}>
				<div>
					<br></br>
					<h4 className={styles.contentColor}>
						INTEGRAMOS SISTEMAS Y BRINDAMOS SOLUCIONES
					</h4>
					<h3> Integración de sistemas</h3>
					<p>
						Muchas veces las empresas ya cuentan con sistemas propietarios
						complejos y en ocasiones requieren agregar nuevas funcionalidades a
						sus aplicativos. En ITC tomamos estas aplicaciones y las integramos
						con nuestra propia solución. Nos encargamos de todas las fases del
						proceso, desde el diseño de la solución, hasta la integración y
						compatibilidad con los sistemas ya existentes.
					</p>
				</div>
				<img
					src="/assets/services/integrationImage.png"
					className={styles.img}
					alt="keeperImage"></img>
			</div>
			<div className={styles.contacus}>
				<h1>¡Ponte en contacto y solicita una cotización!</h1>
				<ContactUsButton />
			</div>
		</>
	);
}

export default SystemsIntegration;
